import { APP_VERSION } from 'constants/app';
import { isMfeMode } from 'constants/isMfeMode';
import {
    IStructuredEvent,
    LogglyService,
    LogService,
    LogLevel,
    IUnstructuredEvent,
    ILogglyConfig,
    ModuleConfig,
    getAnonymousId,
} from '@landr/core';
import { getApplicationName } from 'helpers/executionEnvironment';
import { User } from 'types/generated/graphql';
import { CONFIG } from 'utils/config';

export class Log extends LogService {
    loggly: LogglyService;
    _userProfile?: User | null;

    constructor() {
        super(
            /* eslint-disable no-console */
            {
                log: console.log,
                debug: console.log, // Console.debug not standard
                info: console.log, // Console.info not standard
                warn: console.warn,
                error: console.error,
            },
            /* eslint-enable no-console */
            {
                enabled: CONFIG.VITE_LOG_ENABLED === 'true',
                console: {
                    enabled: CONFIG.VITE_LOG_CONSOLE_ENABLED === 'true',
                    minLevel: CONFIG.VITE_LOG_CONSOLE_MINLEVEL as LogLevel,
                },
                separator: CONFIG.VITE_LOG_SEPARATOR,
            },
        );
        this.loggly = this.createLogglyService();
        this.callback = this.logCallback.bind(this);
    }

    set userProfile(userProfile: User | null) {
        this._userProfile = userProfile;
    }

    protected logCallback(event: IStructuredEvent | IUnstructuredEvent): void {
        const structuredEvent = event as IStructuredEvent;

        // Merge with existing extraInfos or create new object
        structuredEvent.extraInfos = {
            ...structuredEvent.extraInfos,
            isSamplesMfe: isMfeMode ? 'true' : 'false',
        };

        const extraParams = {
            url: window.top?.location.href || window.location.href,
            ...this.formatUserProfileToEventParams(),
        };

        this.loggly.send(structuredEvent, extraParams);
    }

    private formatUserProfileToEventParams() {
        if (this._userProfile) {
            return {
                userId: this._userProfile.userId,
                username: this._userProfile.email || '',
                anonymousId: '',
            };
        } else {
            return {
                userId: '',
                username: '',
                anonymousId: getAnonymousId(),
            };
        }
    }

    private createLogglyService(): LogglyService {
        const config: ILogglyConfig = {
            apiKey: CONFIG.VITE_LOG_LOGGLY_API_KEY as string,
            enabled: CONFIG.VITE_LOG_LOGGLY_ENABLED === 'true',
            minLevel: CONFIG.VITE_LOG_LOGGLY_MINLEVEL as LogLevel,
            tags: CONFIG.VITE_LOG_TAGS as string,
            maxBundleSize:
                Number.parseInt(
                    CONFIG.VITE_LOG_LOGGLY_MAX_BUNDLE_SIZE as string,
                    10,
                ) || 100,
            forcedModule: {} as ModuleConfig,
        };

        const version = APP_VERSION || 'Unknown';
        const application = getApplicationName();

        const properties = {
            serviceKey: CONFIG.VITE_LOG_SERVICE_KEY as string,
            deploymentKind: CONFIG.VITE_LOG_DEPLOYMENT_KIND as string,
            version,
            application,
            consecutiveOccurrences:
                Number.parseInt(
                    CONFIG.VITE_LOG_LOGGLY_CONSECUTIVE_OCCURRENCES as string,
                    10,
                ) || 0,
        };

        return new LogglyService(config, properties, window);
    }
}

export const log = new Log();
