import { AppState, AuthAnalyticsProperties } from '@landr/auth';
import { Environment } from '@landr/core';

export type AbstractAuthUser = {
    city?: string;
    country: string;
    email: string;
    firstname: string;
    displayName?: string;
    homePage?: string;
    isLoggedOnBehalf: boolean;
    lastname?: string;
    libraryId?: string;
    preferredCulture?: string;
    profilePicture72Url?: string;
    state?: string;
    userId: string;
    signupCountryCode?: string;
};

export abstract class AbstractAuth {
    public abstract initialize(options: {
        environment: Environment;
        offlineUrl: string;
    }): Promise<void>;
    public abstract waitForInitialization(): Promise<void>;
    public abstract login(options?: {
        forceLoginPrompt?: boolean;
    }): Promise<void>;
    public abstract signup(): Promise<void>;
    public abstract logout(): Promise<void>;
    public abstract isAuthenticated(): Promise<boolean>;
    public abstract getAccessToken(): Promise<string | null>;
    public abstract getIsSignUp(): boolean;
    // Only implemented by the old auth service, in MFE ecosystem, we don't need this
    public abstract continueOrRedirectToProfileSurvey(): void;
    public abstract getUser(): Promise<AbstractAuthUser | null>;
    public abstract getAnalyticsProperties(): Promise<AuthAnalyticsProperties | null>;
    public abstract getAppState(): AppState | null;
    public abstract getAuthorizationHeader(): Promise<
        Record<string, string> | undefined
    >;
}
