// Basic feature flag service
// This service can only work with anonymous users as the authentication token won't be passed, we don't want to wait for it
// Based on LANDR.Web.Projects/apps/root-app/src/sharedLibs/@landr/root-feature-flags.ts
import { EventIds } from 'constants/eventIds';
import {
    areDevToolsActive,
    getCachedForcedFlags,
    sendToDevTools,
} from '@landr/core';
import { ApplicationEnum } from '@landr/core.models';
import { auth } from 'utils/auth';
import { CONFIG } from 'utils/config';
import { FeatureFlag } from 'types/enums';
import { Observer } from 'utils';
import { fetchErrorHandler } from 'helpers/fetchErrorHandler/fetchErrorHandler';
import { AbstractFeatureFlagService } from './abstract-feature-flag-service';

export type ActiveFlags = {
    [k in FeatureFlag]: boolean;
};

export const defaultFeatureFlags: ActiveFlags = {
    [FeatureFlag.Unboxed]: false,
};

// TODO: CORE-16879 this service won't be used anymore when migrated to MFE
export class LegacyFeatureFlagService extends AbstractFeatureFlagService {
    protected featureFlags: ActiveFlags = defaultFeatureFlags;
    public loadPromise: Promise<void> | null = null;
    public observers = new Set<Observer<Partial<ActiveFlags>>>();

    load(): Promise<void> {
        if (!this.loadPromise) {
            const cachedForcedFlags = getCachedForcedFlags(
                ApplicationEnum.SamplesMarketplace,
            );

            const abortController = new AbortController();

            this.loadPromise = auth.getAccessToken().then(async (token) => {
                try {
                    const url = CONFIG.VITE_MS_FEATURE_FLAG as string;
                    const options = {
                        method: 'GET',
                        headers: {
                            authorization: `Bearer ${token}`,
                        },
                        signal: abortController.signal,
                    };

                    const response = await fetch(url, options);
                    const flagsFromMS = await response.json();

                    if (areDevToolsActive()) {
                        sendToDevTools('set-state', {
                            name: ApplicationEnum.SamplesMarketplace,
                            featureFlags: {
                                ...defaultFeatureFlags,
                                ...flagsFromMS,
                            },
                            forcedFlags: cachedForcedFlags,
                        });
                    }

                    this.featureFlags = {
                        ...defaultFeatureFlags,
                        ...flagsFromMS,
                        ...cachedForcedFlags,
                    };

                    this.broadcast(this.featureFlags);
                } catch (error) {
                    fetchErrorHandler({
                        errorMessage: 'Failed to get feature flag',
                        eventId: EventIds.RequestFlagsFailed,
                        error,
                    });
                }
            });

            // Clean up the abort controller when the promise is resolved or rejected
            this.loadPromise.finally(() => {
                abortController.abort();
            });
        }

        return this.loadPromise;
    }

    get(): ActiveFlags {
        return this.featureFlags;
    }
}
