import React from 'react';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import { createRoot } from 'react-dom/client';
import { setupLandrDevTools } from '@landr/core';
import { ApplicationEnum } from '@landr/core.models';
import setupPolyfills from 'utils/polyfills';
import { CONFIG } from 'utils/config';
import { auth } from 'utils/auth';
import { APP_VERSION } from './constants';
import { RootProviders } from './providers';

const container = document.getElementById('root');
const root = createRoot(container!);

async function init() {
    await auth.waitForInitialization();

    setupPolyfills();
    setupLandrDevTools(ApplicationEnum.SamplesMarketplace, APP_VERSION, CONFIG);

    root.render(<RootProviders />);
}

init();
