import { Observer } from 'utils';
import { FeatureFlag } from 'types/enums';

export type ActiveFlags = {
    [k in FeatureFlag]: boolean;
};

export abstract class AbstractFeatureFlagService {
    protected abstract featureFlags: ActiveFlags;
    protected abstract loadPromise: Promise<void> | null;

    public abstract load(): Promise<void>;
    public abstract get(): ActiveFlags;

    public observers = new Set<Observer<Partial<ActiveFlags>>>();

    public subscribe(observer: Observer<Partial<ActiveFlags>>): void {
        this.observers.add(observer);
    }

    public unsubscribe(observer: Observer<Partial<ActiveFlags>>): void {
        this.observers.delete(observer);
    }

    public broadcast(data: Partial<ActiveFlags>): void {
        this.observers.forEach((observer) => observer.callback(data));
    }
}
