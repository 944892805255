import { isMfeMode } from 'constants/isMfeMode';
import {
    AbstractFeatureFlagService,
    ActiveFlags,
} from './abstract-feature-flag-service';
import {
    LegacyFeatureFlagService,
    defaultFeatureFlags,
} from './legacy-feature-flag-service';

class FeatureFlagService extends AbstractFeatureFlagService {
    protected featureFlags: ActiveFlags = defaultFeatureFlags;
    protected loadPromise: Promise<void> | null = null;
    private service: AbstractFeatureFlagService | null = null;
    private initPromise: Promise<void> | null = null;

    public waitForInitialization(): Promise<void> {
        if (this.service) {
            return Promise.resolve();
        }

        // If not initialized yet, wait for it
        if (!this.initPromise) {
            this.initPromise = new Promise((resolve) => {
                const checkFeatureFlags = () => {
                    if (this.service) {
                        resolve();
                    } else {
                        setTimeout(checkFeatureFlags, 50);
                    }
                };

                checkFeatureFlags();
            });
        }
        return this.initPromise;
    }

    public async initialize(): Promise<void> {
        if (isMfeMode) {
            await import('./mfe-feature-flag-service').then(
                ({ MfeFeatureFlagService }) => {
                    this.service = new MfeFeatureFlagService();
                },
            );
        } else {
            this.service = new LegacyFeatureFlagService();
        }
    }

    public load(): Promise<void> {
        return this.getService().load();
    }

    public get(): ActiveFlags {
        return this.getService().get();
    }

    private getService(): AbstractFeatureFlagService {
        if (!this.service) {
            throw new Error('Feature flags service not initialized');
        }
        return this.service;
    }
}

export const featureFlagService = new FeatureFlagService();
export * from './abstract-feature-flag-service';
